<template>
  <AModalContent class="ape-refund-modal">
    <div class="ape-refund-modal-icon">
      <IAlarm width="50" />
    </div>
    <div class="ape-refund-modal-title">
      This refund is irreversible.
    </div>
    <div class="ape-refund-modal-text">
      <p>
        Don't forget, you have until
        <span>Aug 7, 2023</span> to decide.
      </p>

      <p>
        B.A.P. is committed to delivering what we promised. Just look at some of our most impressive
        <a
          href="https://www.bullsandapesproject.com/accomplishments"
          target="_blank"
        >accomplishments</a> over the last year!
      </p>
    </div>
    <div class="ape-refund-modal-actions">
      <div
        class="ape-refund-modal-cancel ape-refund-modal-action"
        @click.prevent="cancel"
      >
        No action (Keep Ape)
      </div>
      <div
        class="ape-refund-modal-confirm ape-refund-modal-action"
        @click.prevent="confirm"
      >
        Refund
      </div>
    </div>
  </AModalContent>
</template>
<script>
import { defineComponent } from "vue"
import AModalContent from "../Modal/AModalContent.vue"
import IAlarm from "@/assets/img/info.svg?inline"
export default defineComponent({
    props: {
        onCancel: Function,
        onConfirm: Function
    },
    components: { AModalContent, IAlarm },
    setup(props, { emit }) {
        emit('init', { closeOnOverlay: false })
        const cancel = () => {
            props.onCancel && props.onCancel()
            emit('close')
        }
        const confirm = () => {
            props.onConfirm && props.onConfirm()
            emit('close')
        }
        return {
            cancel,
            confirm
        }
    }
})
</script>
<style lang="scss">
.ape-refund-modal {
    padding: 20px 15px;
    width: 400px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--gray);
    border: 1px solid var(--border);
    border-radius: 10px;
    max-height: 100%;
    overflow: auto;
    &-icon {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
    &-title {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: bold;
        text-align: center;
    }
    &-text {
        width: 100%;
        p {
            margin: 10px 0;
        }
        a {
            color: var(--primary);
        }
        span {
            color: var(--primary);
        }
    }

    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
    }
    &-action {
        flex: 1;
        padding: 10px 15px;
        border-radius: 5px;
        color: #fff;
        text-decoration: none;
        transition: 0.2s;
        font-weight: bold;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-sizing: border-box;
        &:hover {
            filter: brightness(0.8);
        }
    }
    &-confirm {
        background-color: var(--red);
    }
    &-cancel {
        background-color: var(--green);
        margin-right: 10px;
    }
    @media screen and (max-width: 576px) {
        &-actions {
            flex-direction: column;
        }
        &-action {
            width: 100%;
        }
        &-cancel {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
}
</style>